
import { defineComponent } from 'vue'

import ArrowArc from '@/assets/svg/arrow-arc.svg?inline'
import LinkIcon from '@/assets/svg/link-icon.svg?inline'

export default defineComponent({
  name: 'StepTwo',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    ArrowArc,
    LinkIcon
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('step', 'explanation_2_header', 'how_it_works', 'explanation_2_paragraph_1', 'explanation_2_paragraph_2', 'explanation_2_sub_header', 'explanation_2_paragraph_3', 'explanation_2_paragraph_1_1', 'referral_link')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
