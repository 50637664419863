
import { defineComponent } from 'vue'
import HowItWorksSection from '@/components/sections/HowItWorksSection.vue'

export default defineComponent({
  name: 'HowItWorks',
  components: {
    HowItWorksSection
  }
})
