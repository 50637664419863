
import { defineComponent } from 'vue'

import GroupIcon from '@/assets/svg/group-icon.svg?inline'
import HandIcon from '@/assets/svg/hand-icon.svg?inline'

export default defineComponent({
  name: 'StepThree',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    GroupIcon,
    HandIcon
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('step', 'explanation_3_1', 'explanation_3_2')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
